export const vTourUrl = "https://conference.vtour.tv/room/";
export const viewerStream = "https://admin-node1.vtour.tv/viewer?stream=";
export const CONFERENCE_URL = "https://v-conference.vtour.tech"
export const ENVIROMENT = window.location.href.includes('test') || window.location.href.includes('localhost') || window.location.href.includes('192') ? "dev" : "prod";

let url = "";
let SOCKET_URL = "";

if (window.location.href.includes('localhost') || window.location.href.includes('192')) {
    url = "https://admin-node1.vtour.tv";
    SOCKET_URL = "https://network-service-backend.vtour.tv";
    // url = "http://192.168.0.111:3000";
} else if (window.location.href.includes('test')) {
    url = "https://admin-node1-test.vtour.tv";
    SOCKET_URL = "https://socket-test.vtour.tv";
} else {
    url = "https://admin-node1.vtour.tv";
    SOCKET_URL = "https://network-service-backend.vtour.tv";
}

export let server = url;

export const baseURL = `${server}/web`;
export const NETWORK_BASE_URL = `${SOCKET_URL}/web`;
export const NETWORK_SOCKET_BASE_URL = `${SOCKET_URL}`;


export const CHART_URL = "https://charts.mongodb.com/charts-vtour-mcuns"
export const CHART_ID = window.location.href.includes('test') || window.location.href.includes('localhost') || window.location.href.includes('192') ? "ef4a63b3-24f6-4ff5-844f-2c4239af93f5" : "1fb434da-1375-4528-bb13-328cfcba4eee";

export const MILLICAST_ACCOUNT_ID = "9ZLpN7";
export const MILLICAST_PUBLISH_TOKEN = "215f26e56e79658f31005d6fbc144c7f9ed7ab7302b09c838aba6d1ca10486df"
