import axios from 'axios';

const urlConfig = require('../config');


axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.post['Content-Type'] = 'application/json';

var config = {
    headers: { 'Authorization': "Bearer " + localStorage.getItem('vtourUsertoken') }
};


const api = {

    socket: {
        moderatorSpeakerUrl: urlConfig.modSpeakerChatUrl,
        moderatorGuestUrl: urlConfig.moderatorGuestChatUrl,
        analyticsUrl: urlConfig.analyticsUrl,
    },

    userAuth: {
        token: '',
        role: ''
    },

    updateHeader() {
        config.headers = { 'Authorization': "Bearer " + localStorage.getItem('vtourUsertoken') }
        this.userAuth['token'] = localStorage.getItem('vtourUsertoken');
        this.userAuth['role'] = localStorage.getItem('role');
    },

    userCtrl: {
        registerUser: user => axios.post(urlConfig.baseURL + '/user/add', user, config),
        updateUser: user => axios.put(urlConfig.baseURL + '/user/update', user, config),
        login: user => axios.post(urlConfig.baseURL + '/user/login', user),
        organizerLogin: user => axios.post(urlConfig.baseURL + '/organizer/login', user),
        userList: (page, size, role) => axios.get(urlConfig.baseURL + '/user/list', { params: { page: page, size: size, role: role }, ...config }),
        editUser: (user_uid, role) => axios.get(urlConfig.baseURL + '/user/edit', { params: { user_uid: user_uid, role: role }, ...config }),
    },

    eventCtrl: {
        registerEvent: event => axios.post(urlConfig.baseURL + '/event/add', event, config),
        uploadLogo: (file) => axios.post(urlConfig.baseURL + '/event/upload-logo/', file, config),
        eventList: filter => axios.post(urlConfig.baseURL + '/event/list', filter, config),
        getEvent: (organizer_uid, event_uid) => axios.get(urlConfig.baseURL + '/event/get', { params: { event_uid: event_uid, organizer_uid: organizer_uid }, ...config }),
        archiveEvent: (organizer_uid, event_uid, archiveValue) => axios.put(urlConfig.baseURL + '/event/archive', {}, { params: { organizer_uid: organizer_uid, event_uid: event_uid, archive: archiveValue }, ...config }),
        deleteEvent: (organizer_uid, event_uid, archiveValue) => axios.delete(urlConfig.baseURL + '/event/delete', { params: { organizer_uid: organizer_uid, event_uid: event_uid, archive: archiveValue }, ...config }),
        updateEvent: event => axios.put(urlConfig.baseURL + '/event/update', event, config),
        getSpeakerList: event_uid => axios.get(urlConfig.baseURL + '/event/get-speaker-list/' + event_uid, config),
        notifyGuests: notification => axios.post(urlConfig.baseURL + '/notify-guest', notification, config),
        registerSpeaker: speaker => axios.post(urlConfig.baseURL + '/event/register-speaker', speaker, config),
        eventNameList: () => axios.get(urlConfig.baseURL + '/event/event-name-list', config),
        updateScene: (organizer_uid, archiveValue, event_uid, sceneName, sceneValue) => axios.put(urlConfig.baseURL + '/event/update-scene', { organizer_uid: organizer_uid, event_uid: event_uid, archive: archiveValue, sceneName: sceneName, sceneValue: sceneValue }, config),
        featuresAccessible: queryParams => axios.get(urlConfig.baseURL + '/event/features-accessible', { params: queryParams, ...config })
    },


    enquiryFormsCtrl: {
        count: (obj) => axios.get(urlConfig.baseURL + '/enquiry-forms/count', { params: obj, ...config }),
        download: (obj) => axios.get(urlConfig.baseURL + '/enquiry-forms/download', { params: obj, responseType: 'blob', ...config })
    },

    networkCtrl: {
        registerNetwork: network => axios.post(urlConfig.baseURL + '/network/add', network, config),
        networkList: (id, filter) => axios.post(urlConfig.baseURL + '/network/list/' + id, filter, config),
        getNetwork: uid => axios.get(urlConfig.baseURL + '/network/get/' + uid, config),
        updateNetwork: network => axios.put(urlConfig.baseURL + '/network/update', network, config),
        deleteNetwork: obj => axios.delete(urlConfig.baseURL + '/network/delete', { params: { event_uid: obj.event_uid, room_uid: obj.room_uid }, ...config }),
    },

    uploadCtrl: {
        uploadFile: (event_uid, file) => axios.post(urlConfig.baseURL + '/upload/file/' + event_uid, file, config),
    },

    conferenceCtrl: {
        registerConference: obj => axios.post(urlConfig.baseURL + '/conference/add', obj, config),
        updateConference: conference => axios.post(urlConfig.baseURL + '/conference/update', conference, config),
        conferenceList: (id, filter) => axios.post(urlConfig.baseURL + '/conference/list/' + id, filter, config),
        archiveConference: obj => axios.put(urlConfig.baseURL + '/conference/archive', {}, { params: { event_uid: obj.event_uid, conference_uid: obj.conference_uid, archive: obj.archive, enable: obj.enable }, ...config }),
        deleteConference: obj => axios.delete(urlConfig.baseURL + '/conference/delete', { params: { event_uid: obj.event_uid, conference_uid: obj.conference_uid, archive: obj.archive, enable: obj.enable }, ...config }),
        changeStatus: obj => axios.put(urlConfig.baseURL + '/conference/change-status', {}, { params: obj, ...config }),
        changeEmoticonStatus: obj => axios.put(urlConfig.baseURL + '/conference/emoticon-status', {}, { params: obj, ...config }),
        changeChatStatus: obj => axios.put(urlConfig.baseURL + '/conference/chat-status', {}, { params: obj, ...config }),
        nameList: obj => axios.get(urlConfig.baseURL + '/conference/name-list', { params: obj, ...config }),
        updateToken: conference => axios.put(urlConfig.baseURL + '/conference/update-token', conference, config),
        get: params => axios.get(urlConfig.baseURL + '/conference/get', { params: params, ...config }),
    },


    conferenceLogsCtrl: {
        saveConferenceLogs: conference => axios.post(urlConfig.baseURL + '/conference-log/add', conference, config),
        getConferenceLogs: conference_uid => axios.get(urlConfig.baseURL + '/conference-log/list/' + conference_uid, config),
        deleteConferenceLog: conference_uid => axios.delete(urlConfig.baseURL + '/conference-log/delete/' + conference_uid, config)
    },

    guestCtrl: {
        addGuest: guest => axios.post(urlConfig.baseURL + '/guest/add', guest, config),
        uploadGuestList: (event_uid, group, file) => axios.post(urlConfig.baseURL + '/guest/upload/' + event_uid, file, { timeout: 3600000, params: { group: group }, ...config }),
        guestList: (event_uid, filter) => axios.post(urlConfig.baseURL + '/guest/list/' + event_uid, filter, config),
        deleteGuest: obj => axios.delete(urlConfig.baseURL + '/guest/delete', { params: obj, ...config }),
        exportGuest: event_uid => axios.get(urlConfig.baseURL + '/guest/export/' + event_uid, { responseType: 'blob', ...config }),
        kickoutGuest: (obj) => axios.put(urlConfig.baseURL + '/guest/kickout', obj, config),
        guestNameList: obj => axios.get(urlConfig.baseURL + '/guest/name-list', { params: obj, ...config }),
        kickoutGroup: obj => axios.put(urlConfig.baseURL + '/guest/kickout-group', obj, config),
        guestCount: obj => axios.get(urlConfig.baseURL + '/guest/count', { params: obj, ...config }),
    },

    eventLogsCtrl: {
        eventLogsList: (eventUid, filter) => axios.post(urlConfig.baseURL + '/event-log/list/' + eventUid, filter, config),
        stallVisitors: (obj) => axios.get(urlConfig.baseURL + '/event-log/stall-visitors', { params: obj, ...config }),
        stallGuestList: (obj) => axios.get(urlConfig.baseURL + '/event-log/stall-guest-list/', { params: obj, ...config }),
    },

    vtourCtrl: {
        getSpeakerLink: speakerList => axios.post(urlConfig.vTourUrl, speakerList),
        deleteRoom: roomId => axios.delete(urlConfig.vTourUrl + roomId)
    },


    speakerCtrl: {
        login: speaker => axios.post(urlConfig.baseURL + '/speaker/login', speaker),
        conferenceList: event_uid => axios.get(urlConfig.baseURL + '/speaker/conference-name-list/' + event_uid, config),
        conferenceLogList: conference_uid => axios.get(urlConfig.baseURL + '/speaker/conference-log-list/' + conference_uid, config),
        add: speaker => axios.post(urlConfig.baseURL + '/speaker/add', speaker, config),
        update: speaker => axios.put(urlConfig.baseURL + '/speaker/update', speaker, config),
        delete: (eventUid, speakerUid) => axios.delete(urlConfig.baseURL + `/speaker/delete/${eventUid}/${speakerUid}`, config),
        getSpeakerList: (eventUid, filter) => axios.post(urlConfig.baseURL + `/speaker/get-list/${eventUid}`, filter, config),
    },

    sessionCtrl: {
        add: session => axios.post(urlConfig.baseURL + '/session/add', session, config),
        update: session => axios.put(urlConfig.baseURL + '/session/update', session, config),
        updateParticipant: session => axios.put(urlConfig.baseURL + '/session/update-participant', session, config),
        delete: (params) => axios.delete(urlConfig.baseURL + `/session/delete`, { params: params, ...config }, config),
        sessionList: (page, size, event_uid, conference_uid) => axios.get(urlConfig.baseURL + `/session/get-list`, { params: { event_uid: event_uid, conference_uid: conference_uid, page: page, size: size }, ...config }),
        getLinks: (role) => axios.get(urlConfig.baseURL + `/session/get-links`, config),
        getGuestLinks: (obj) => axios.get(urlConfig.baseURL + `/session/get-guest-links`, { params: obj, ...config }),
        updateGuestLinks: guests => axios.put(urlConfig.baseURL + '/session/update-guest-links', guests, config),
        generateGuestLink: (obj) => axios.get(urlConfig.baseURL + '/session/generate-guest-link', { params: obj, ...config }),
    },

    analyticsCtrl: {
        event: filter => axios.post(urlConfig.baseURL + '/event-analytics', filter, config),
        eventList: () => axios.get(urlConfig.baseURL + `/analytics/event-list`, config)
    },

    stallOwnerCtrl: {
        registerStallOwner: stallOwner => axios.post(urlConfig.baseURL + '/stall-owner/add', stallOwner, config),
        updateStallOwner: stallOwner => axios.put(urlConfig.baseURL + '/stall-owner/update', stallOwner, config),
        deleteStallOwner: (stallOwnerUid, event_uid) => axios.delete(urlConfig.baseURL + '/stall-owner/delete', { params: { stall_owner_uid: stallOwnerUid, event_uid: event_uid }, ...config }),
        loginStallOwner: stallOwnerUid => axios.post(urlConfig.baseURL + '/stall-owner/login/', stallOwnerUid, config),
        stallOwnerList: (page, size, event_uid) => axios.get(urlConfig.baseURL + '/stall-owner/list', { params: { event_uid: event_uid, page: page, size: size }, ...config }),
        stallList: (page, size) => axios.get(urlConfig.baseURL + '/stall-owner/stall-list', { params: { page: page, size: size }, ...config }),

        createMetaData: (obj) => axios.post(urlConfig.baseURL + '/stall-owner/create-metadata', obj, config),
        getChat: (obj) => axios.post(urlConfig.baseURL + '/stall-owner/get-chat', obj, config),
        markRead: (obj) => axios.get(urlConfig.baseURL + '/stall-owner/mark-read', { params: obj, ...config }),
        metaDataList: (obj) => axios.post(urlConfig.baseURL + '/stall-owner/metadata-list', obj, config),
    },

    meetHubCtrl: {
        getToken: (payload) => axios.post(urlConfig.baseURL + '/meet-hub/get-token', payload, config)
    },


    stallCtrl: {
        registerStall: stall => axios.post(urlConfig.baseURL + '/stall/add', stall, config),
        stallList: (event_uid, filter) => axios.get(urlConfig.baseURL + '/stall/list', { params: { event_uid: event_uid, page: filter.page, size: filter.size }, ...config }),
        getStall: (event_uid, stall_uid, enable, archive) => axios.get(urlConfig.baseURL + '/stall/get', { params: { event_uid: event_uid, stall_uid: stall_uid, archive: archive, enable: enable }, ...config }),
        updateStall: stall => axios.put(urlConfig.baseURL + '/stall/update', stall, config),
        archiveStall: (stall_uid, archive, event_uid, enable) => axios.put(urlConfig.baseURL + '/stall/archive', {}, { params: { event_uid: event_uid, stall_uid: stall_uid, archive: archive, enable: enable }, ...config }),
        changeStallStatus: (obj) => axios.put(urlConfig.baseURL + '/stall/change-status', {}, { params: obj, ...config }),
        changeStallVideoCallStatus: (obj) => axios.put(urlConfig.baseURL + '/stall/change-video-call-status', {}, { params: obj, ...config }),
        deleteStall: (obj) => axios.delete(urlConfig.baseURL + '/stall/delete/', { params: obj, ...config })
    },

    zoomCtrl: {
        add: obj => axios.post(urlConfig.baseURL + '/zoom/add', obj, config),
        update: zoom => axios.post(urlConfig.baseURL + '/zoom/update', zoom, config),
        // updateStatus: zoom => axios.put(urlConfig.NETWORK_BASE_URL + '/zoom/update-status', zoom, config),
        list: (id, filter) => axios.post(urlConfig.baseURL + '/zoom/list/' + id, filter, config),
        delete: obj => axios.delete(urlConfig.baseURL + '/zoom/delete', { params: obj, ...config }),
    },

    helpDeskCtrl: {
        login: obj => axios.post(urlConfig.baseURL + '/help-desk/login', obj, config),
        get: obj => axios.get(urlConfig.baseURL + '/help-desk/get', { params: obj, ...config }),
        changeHelpDeskStatus: obj => axios.put(urlConfig.baseURL + '/help-desk/change-status', obj, config),
        GetChat: obj => axios.post(urlConfig.baseURL + '/help-desk/get-chat', obj, config),
        MarkAllRead: obj => axios.get(urlConfig.baseURL + '/help-desk/mark-chat-read', { params: obj, ...config }),
        UserList: obj => axios.get(urlConfig.baseURL + '/help-desk/user-list', { params: obj, ...config })
    },

    notificationCtrl: {
        saveNotification: notification => axios.post(urlConfig.baseURL + '/notification/save', notification, config),
    },

    emitCtrl: {
        changeConferenceStatus: obj => axios.post(urlConfig.NETWORK_BASE_URL + '/conference/change-status', obj, config),
        eventList: obj => axios.get(urlConfig.NETWORK_BASE_URL + '/event/list', { params: obj, ...config }),
        kickoutGuest: obj => axios.post(urlConfig.NETWORK_BASE_URL + '/guest/kickout', obj, config),
        inviteGuest: obj => axios.post(urlConfig.NETWORK_BASE_URL + '/guest/invite', obj, config),
        moveToGuest: obj => axios.post(urlConfig.NETWORK_BASE_URL + '/guest/move-to', obj, config),
        onlineGuest: obj => axios.get(urlConfig.NETWORK_BASE_URL + '/organizer/online-guest-list', { params: obj, ...config }),
        sendNotification: obj => axios.post(urlConfig.NETWORK_BASE_URL + '/notification/send', obj, config),
        updateScene: obj => axios.post(urlConfig.NETWORK_BASE_URL + '/event/update-scene', obj, config),
        stallChangeStatus: obj => axios.post(urlConfig.NETWORK_BASE_URL + '/stall/change-status', obj, config),
        updateZoomStatus: obj => axios.post(urlConfig.NETWORK_BASE_URL + '/zoom/update-status', obj, config),
        stallActiveGuestList: obj => axios.get(urlConfig.NETWORK_BASE_URL + '/stall-owner/active-guest-list', { params: obj, ...config }),
        regionCount: obj => axios.get(urlConfig.NETWORK_BASE_URL + '/analytics/region-count', { params: obj, ...config }),
        changePollStatus: obj => axios.post(urlConfig.NETWORK_BASE_URL + '/poll/change-status', obj, config),
        changeGroupPolicy: obj => axios.post(urlConfig.NETWORK_BASE_URL + '/group/change-policy', obj, config),
        guestCount: obj => axios.post(urlConfig.NETWORK_BASE_URL + '/guest/count', obj, config),
        clearChat: obj => axios.post(urlConfig.NETWORK_BASE_URL + '/chat/clear', obj, config),
    },

    questionCtrl: {
        add: obj => axios.post(urlConfig.baseURL + '/question/add', obj, config),
        get: obj => axios.get(urlConfig.baseURL + '/question/get', { params: obj, ...config }),
        update: obj => axios.put(urlConfig.baseURL + '/question/update', obj, config),
        changeStatus: obj => axios.put(urlConfig.baseURL + '/question/change-status', obj, config),
        list: (filter) => axios.post(urlConfig.baseURL + '/question/list/', filter, config),
        delete: obj => axios.delete(urlConfig.baseURL + '/question/delete', { params: obj, ...config }),
    },

    answerCtrl: {
        download: obj => axios.post(urlConfig.baseURL + '/answer/download', obj, { responseType: 'blob', ...config }),
    },

    groupCtrl: {
        add: obj => axios.post(urlConfig.baseURL + '/group/add', obj, config),
        update: obj => axios.put(urlConfig.baseURL + '/group/update', obj, config),
        list: queryParams => axios.get(urlConfig.baseURL + '/group/list', { params: queryParams, ...config }),
        delete: obj => axios.delete(urlConfig.baseURL + '/group/delete', { params: obj, ...config }),
        deleteGuestGroup: obj => axios.put(urlConfig.baseURL + '/group/delete-guest-group', {}, { params: obj, ...config }),
        get: obj => axios.get(urlConfig.baseURL + '/group/get', { params: obj, ...config }),
        sendInvitation: obj => axios.post(urlConfig.baseURL + '/group/send-invitation', obj, config)
    },

    conferenceChatCtrl: {
        list: obj => axios.post(urlConfig.baseURL + '/conference-chat/list', obj, config),
        archive: obj => axios.put(urlConfig.baseURL + '/conference-chat/archive', obj, config),
    },

    sessionChatCtrl: {
        list: obj => axios.post(urlConfig.baseURL + '/session-chat/list', obj, config),
        archive: obj => axios.put(urlConfig.baseURL + '/session-chat/archive', obj, config),
    },

    networkChatCtrl: {
        list: obj => axios.post(urlConfig.baseURL + '/network-chat/list', obj, config),
        archive: obj => axios.put(urlConfig.baseURL + '/network-chat/archive', obj, config),
    },

    helpDeskMetadataCtrl: {
        get: queryParams => axios.get(urlConfig.baseURL + '/helpdesk-metadata/get', { params: queryParams, ...config }),
    },

    streamSettingCtrl: {
        add: obj => axios.post(urlConfig.baseURL + '/stream-settings/add', obj, config),
        update: obj => axios.put(urlConfig.baseURL + '/stream-settings/update', obj, config),
        get: queryParams => axios.get(urlConfig.baseURL + '/stream-settings/get', { params: queryParams, ...config }),
        getPublishToken: queryParams => axios.get(urlConfig.baseURL + '/stream-settings/get-publish-token', { params: queryParams, ...config }),
        updatePublishToken: queryParams => axios.get(urlConfig.baseURL + '/stream-settings/update-publish-token', { params: queryParams, ...config })
    },

    milicastCtrl: {
        subscriberTokenlist: queryParams => axios.get(urlConfig.baseURL + '/millicast/subscribe-token-list', { params: queryParams, ...config }),
        add: obj => axios.post(urlConfig.baseURL + '/millicast/add', obj, config),
        remove: token => axios.delete(urlConfig.baseURL + '/millicast/remove/' + token, config),
        resetAccount: queryParams => axios.delete(urlConfig.baseURL + '/millicast/reset-account', { params: queryParams, ...config })
    },

    eventPointsCtrl: {
        add: obj => axios.post(urlConfig.baseURL + '/event-points/add', obj, config),
        update: obj => axios.put(urlConfig.baseURL + '/event-points/update', obj, config),
        get: queryParams => axios.get(urlConfig.baseURL + '/event-points/get', { params: queryParams, ...config })
    },

    commonCtrl: {
        getToken: obj => axios.post(urlConfig.baseURL + '/common/get-token', obj, config)
    }
};

export default api;
