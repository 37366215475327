import React, { Component } from 'react';
import { Route, HashRouter as Router, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';

import api from './api/api';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

const Login = React.lazy(() => import('./views/Pages/Login/Login'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));


api.updateHeader();


class App extends Component {

  render() {
    return (
      <Router>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route exact path="/" name="Login Page" render={props => <Login {...props} />} />
            <Route exact path="/not-found" name="Page 404" render={props => <Page404 {...props} />} />
            <Route path="/" name="Home" render={props => <DefaultLayout {...props} />} />
          </Switch>
        </React.Suspense>
      </Router>
    );
  }
}

export default App;
